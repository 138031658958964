<template>
  <el-menu  :default-openeds="[defaultOpenedIndex]" :default-active="ActiveIndex"  router class="el-menu-vertical-demo" @select="handleMenuSelect" @open="handleOpen"
    @close="handleClose" unique-opened :collapse="isCollapse">
    <div class="lefticon">
      <el-a @click="toggleCollapse" class="toggle-btn">
        <el-icon v-if="isCollapse" class="iconfont icon-zhankai"></el-icon>
        <el-icon v-else class="iconfont icon-a-shouqi2"></el-icon>
      </el-a>
    </div>
    <el-sub-menu v-for="item in options" :key="item.id" :index="item.id.toString()">
      <template #title>
        <i :class="['iconfont', item.iconClass]"></i>
        <span>{{ item.label }}</span></template>
      <el-menu-item v-for="subItem in item.subOptions" :key="subItem.id" :index="subItem.id.toString()"
        @click="handleMenuClick(subItem.label, subItem.link)">
        <span>{{ subItem.label }}</span>
      </el-menu-item>
    </el-sub-menu>
  </el-menu>
</template>
<script setup>
import { ref,computed } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
const isCollapse = ref(false); // 将初始值设置为 false，以默认展开菜单
// 切换 isCollapse 的值
const toggleCollapse = () => {
  isCollapse.value = !isCollapse.value;
};

// 更新当前激活的菜单项（此处仅打印，未做实际处理）
const handleOpen = (key, keyPath) => {
  console.log(key, keyPath);
};

const handleClose = (key, keyPath) => {
  console.log(key, keyPath);
};
const ActiveIndex = ref(1)
const options = ref([
  {
    id: 1,
    iconClass: "icon-shuju2",
    label: "在线分析",
    subOptions: [
      { id: 11, label: "热图", link: "/01_heatmap" },
      { id: 12, label: "风污染玫瑰", link: "/02_rose" },
      { id: 13, label: "玫瑰矩阵", link: "/02_rose1" },
      { id: 14, label: "相关性拟合图", link: "/04_corrmap" },
      { id: 15, label: "相关性矩阵分析", link: "/05_Matrix" },
      { id: 16, label: "贡献排名", link: "/06_voctopnmap" },
      { id: 303, label: "VOCs组分占比", link: "/07_vocpiemap" },
      { id: 18, label: "VOCs浓度逐日变化", link: "/08_vocdailymap" },
      { id: 19, label: "VOCs浓度日变化", link: "/09_vochourmap" },
      { id: 20, label: "VOC组分及其贡献比", link: "/10_voccontritable" },
      { id: 21, label: "SOA生成贡献表", link: "/12_voccontritable_i" },
      { id: 22, label: "VOCs箱线图", link: "/11_vocboxmap" },
      { id: 23, label: "时间序列图", link: "/13_timeztmap" },
      { id: 301, label: "三元相", link: "/15_ternarymap_i" },
      { id: 302, label: "比值图", link: "/16_ratiomap_i" },
    ],
  },
  {
    id: 2,
    label: "离线分析",
    iconClass: "icon-a-diannaojisuanjizoushiqushixingneng",
    subOptions: [
      { id: 24, label: "热图", link: "/1_heatmap" },
      { id: 17, label: "污染日历", link: "/17_rili2" },
      { id: 13, label: "Gis空间分布", link: "/03_Gis" },
      { id: 27, label: "风污染玫瑰", link: "/2_rose" },
      { id: 40, label: "日变化", link: "/14_ribianhua2" },
      { id: 402, label: "日变化2", link: "/14_ribianhua2_Multiplelines" },
      { id: 28, label: "相关性拟合图", link: "/4_corrmap" },
      { id: 29, label: "因子相关矩阵图", link: "/5_Matrix" },
      { id: 30, label: "贡献排名", link: "/6_voctopnmap" },
      { id: 28, label: "玫瑰矩阵", link: "/2_rose1" },
      { id: 31, label: "占比图", link: "/7_vocpiemap" },
      { id: 32, label: "VOCs浓度逐日图", link: "/8_vocdailymap" },
      { id: 33, label: "VOCs浓度日变化", link: "/9_vochourmap" },
      { id: 34, label: "VOC组分及贡献比", link: "/10_voccontritable2" },
      { id: 35, label: "VOCs箱线图", link: "/11_vocboxmap2" },
      { id: 36, label: "SOA生成贡献表", link: "/12_voccontritable_i2" },
      { id: 37, label: "时间序列", link: "/13_timeztmap2" },
      { id: 38, label: "三元相", link: "/15_ternarymap_i2" },
      { id: 39, label: "比值图", link: "/16_ratiomap_i2" },
    ],
  },
  {
    id: 3,
    label: "颗粒物分析",
    iconClass: "icon-shujuchuli",
  },
  {
    id: 4,
    label: "光化学分析",
    iconClass: "icon-jianguanbaosong",
    subOptions: [
      { id: 41, label: "贡献排名", link: "/Developing" },
      { id: 42, label: "饼图", link: "/Developing" },
      { id: 43, label: "VOCs浓度逐日图", link: "/Developing" },
      { id: 44, label: "VOCs浓度日变化", link: "/Developing" },
      { id: 45, label: "VOC组分", link: "/Developing" },
      { id: 46, label: "SOA贡献表", link: "/Developing" },
      { id: 47, label: "VOCs箱线图", link: "/Developing" },
      { id: 48, label: "时间序列图", link: "/Developing" },
    ],
  },
  {
    id: 5,
    label: "数据预处理",
    iconClass: "icon-jiaoyichaxun",
    subOptions: [
      { id:101, label: "VOCs", link: "/Developing" },
      { id: 102, label: "颗粒物", link: "/Developing" },
      { id: 103, label: "PMF", link: "/Developing" },
      { id: 104, label: "Gdas", link: "/Developing" },
      { id: 105, label: "EKMA", link: "/Developing" },
      { id: 106, label: "垂直风杨", link: "/Developing" },
    ],
  },
  {
    id: 6,
    label: "报表分析",
    iconClass: "icon-wentifankui",
    subOptions: [
      { id: 201, label: "日报", link: "/ReportDow" },
      { id: 202, label: "月报", link: "/ReportDow" },
      { id: 203, label: "季报", link: "/ReportDow" },
    ],
  }
  ,
  {
    id: 7,
    label: "数据管理",
    iconClass: "icon-liuchengguanli",
    subOptions: [
      { id: 204, label: "离线数据导入", link: "/uploadData" },
    ],
  },
  {
    id: 8,
    label: "工具箱",
    iconClass: "icon-liuchengguanli",
    subOptions: [
      { id: 204, label: "数据清洗", link: "/pushdata" },
    ],
  },
]);
 // 计算默认展开的索引
//  const defaultOpenedIndex = computed(() => {
//     if (options.value.length > 0) {
//       return options.value[0].id.toString();
//     }
//     return null;
//   });
// 使用 defineEmits 初始化事件发送者
const emit = defineEmits(["tell"]);
const handleMenuClick = (label, link) => {
  // Perform the route navigation here
  console.log("Navigating to:", label,link);
  emit("tell", { key: "navadd", title: link, labels: label });
};
</script>
<style scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  text-align: left;
  height: 93vh; background-color: #f9fafb;}

.el-sub-menu__title span {
  font-size: 15px !important;
  letter-spacing: 1px;
  font-weight: bold;
  margin-right:10px;
}

i {
  font-size: 20px;
}

.leftwap {
  position: relative;
}

.toggle-btn {
  position: fixed;
  left: 21px;
  top: 70px;
  z-index: 99;
  background: #fff;
}

.toggle-btn i {
  color: #999;
}

/* 全局图标样式 */
.iconfont {
  font-size: 16px;
  color: #999;
}

.el-menu--collapse {
  height: 100%;
}

.el-menu-item.is-active {
  background-color: #ecf5ff;
}

.el-sub-menu__title .iconfont {
  margin-right: 8px;
}

/* 左侧菜单添加滚动条 */
.el-menu {
  overflow-y: scroll;
  padding-top: 30px;
}

/* 高度 */
.el-submenu .el-menu-item {
  height: 46px;
}

.el-menu-item.is-active {
  border-right: 2px solid #409eff;
}
</style>